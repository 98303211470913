import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";
const data = [
  {
    icon: <HiShieldCheck/>,
    heading: "Best interest rates on the market",
    detail:
      "Increasing rates have a profound effect on value of income-producing real estate-just like they do on any other investment vehicle.We provide a best interest rates in the market",
  },
  {
    icon: <MdCancel />,
    heading: "Prevent unstable prices",
    detail:
      "The housing market is influenced by the state of the economy,interest rates,real income and changes in size of the population.Determined by detecting price bubbles as they develop to prevent unstable prices",
  },
  {
    icon: <MdAnalytics />,
    heading: "Best price on the market",
    detail:
      "A comparable property is one that is similar in size,condition,neighborhood and amenities.We provide all the facilities and at a affordable prices on the market.",
  },
];
export default data;