import "./Companies.css"

const Company =()=>{
    return(
        <section className="c-wrapper">
            <div className="paddings innerWidth flexCenter c-container">
                <img src="./prologis.png" alt="" ></img>
                <img src="./tower.png" alt=""></img>
                <img src="./equinix.png" alt="" ></img>
                <img src="./realty.png" alt="" ></img>
            </div>
        </section>
    )
}
export default Company;